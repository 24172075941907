<template>
  <div>
    <v-header></v-header>
    <div class="container">
      <template v-for="item in list">
        <span class="title">{{item.name}}</span>

        <div v-html="item.value" class="content"></div>
      </template>
    </div>
  </div>
</template>

<script>
  import {user} from "../../api";

  export default {
    name: "about",
    data() {
      return {
        list: []
      }
    },
    created() {
      this.getData();
    },

    methods: {
      async getData() {
        const res = await user.about(`codes=USER_PROTOCOL&codes=MEMBERSHIP_CLAUSE&codes=PRIVACY_STATEMENT`);
        const list = res.data;
        const routeCodeMap = {
          'agreement': 'USER_PROTOCOL',
          'aboutVip': 'MEMBERSHIP_CLAUSE',
          'aboutUpload': 'PRIVACY_STATEMENT'
        };
        console.log(res.data)

        const code = routeCodeMap[this.$route.name];
        if (code && code.length) {
          this.list = res.data.filter(row => row.code === code);
        }
      }
    }
  }
</script>

<style scoped lang="less">
  .container {
    height: calc(100vh - var(--header-height));
    overflow-y: auto;
    padding: var(--header-height) 28px 28px;

    .title {
      font-weight: bold;
      padding: 10px 0;
    }

    .content {
      p, span {
        letter-spacing: 4px;
        color: #999 !important;
      }
    }
  }
</style>
